import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import Property from '../../../models/Property';

export interface DeleteClusterModalState {
    isOpen: boolean;
    property?: Property;
    clusterId?: string;
}

const initialState: DeleteClusterModalState = {
    isOpen: false,
};

export const slice = createSlice({
    name: 'deleteClusterModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setProperty: (
            state,
            action: PayloadAction<Property | null | undefined>
        ) => {
            if (!action.payload) return;
            state.property = action.payload;
        },
        setClusterId: (state, action: PayloadAction<string>) => {
            if (!action.payload) return;
            state.clusterId = action.payload;
        },
    },
});

export const { setOpen, setClusterId, setProperty } = slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const selectIsOpen = (state: RootState) =>
    state.deleteClusterModal.isOpen;
export const selectProperty = (state: RootState) =>
    state.deleteClusterModal.property;
export const selectClusterId = (state: RootState) =>
    state.deleteClusterModal.clusterId;
