import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

export interface SessionDetailsModalState {
    isOpen: boolean;
    allowChargingCustomer: boolean;
    sessionId?: string;
}

const initialState: SessionDetailsModalState = {
    isOpen: false,
    allowChargingCustomer: false,
};

export const slice = createSlice({
    name: 'sessionDetailsModal',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setAllowChargingCustomer: (state, action: PayloadAction<boolean>) => {
            state.allowChargingCustomer = action.payload;
        },
        setSessionId: (
            state,
            action: PayloadAction<string | null | undefined>
        ) => {
            if (!action.payload) return;
            state.sessionId = action.payload;
        },
    },
});

export const { setOpen, setAllowChargingCustomer, setSessionId } =
    slice.actions;
export const actions = slice.actions;

export default slice.reducer;

export const selectIsOpen = (state: RootState) =>
    state.sessionDetailsModal.isOpen;
export const selectAllowChargingCustomer = (state: RootState) =>
    state.sessionDetailsModal.allowChargingCustomer;
export const selectSessionId = (state: RootState) =>
    state.sessionDetailsModal.sessionId;
